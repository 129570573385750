@import "../../../node_modules/bootstrap/scss/bootstrap";

@media screen and ( max-width: 576px) {
 .konvajs-content {
    height: 100% !important;
    min-height: 55px !important;
    width: 100% !important;
    canvas {
      max-height: 60px !important;
      width: 100% !important;
      height: 100% !important;
    }
}
}