$body-bg: #160823;
$body-color: #fff;
$primary: #ff9400;

$theme-colors: (
        "primary": $primary,
        "danger": #ff0000,
        "info": #59dbef,
);

//tabs
$nav-tabs-border-color: darken($primary, 20%);
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: darken($primary, 20%);
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg $nav-tabs-link-active-bg $nav-tabs-link-active-bg;
$nav-tabs-link-hover-border-color: $nav-tabs-link-active-bg $nav-tabs-link-active-bg $nav-tabs-link-active-bg;

//cards
$card-color: $body-color;
$card-bg: lighten($body-bg, 5%);

@import "../node_modules/bootstrap/scss/bootstrap";
